import * as React from 'react'
import { Link } from "gatsby"
import Layout from '../components/layout'

const SubscribeThankYou = () => {
    return (
        <Layout>
        <div className="min-h-screen bg-slate-100">
            <div className="mx-auto max-w-3xl py-24 px-4 sm:px-10 sm:py-52 lg:px-12">
                <div className="flex flex-col">
                    <h3 className="text-center">Thank you!</h3>
                    <p className="text-center">
                        We send our email newsletter roughly once a week, so you should hear from us soon. 
                        In the meantime, check out <Link to="/heat-pump-savings"> our guide on heat pumps</Link>, 
                        one of the most overlooked climate solutions. 
                    </p>
                </div>
            </div>
        </div>
        </Layout>
    )
}

export default SubscribeThankYou